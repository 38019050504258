import "./skip-link-focus-fix";
import AOS from "aos";
import "script-loader!slick-carousel";
import "script-loader!lity";
import "script-loader!slick-lightbox";

(function ($) {
  AOS.init({ once: true, duration: 700 });

  var header = $("#masthead"),
    siteContent = $("#content");

  $(document).ready(function () {
    sidebarMenu();
    searchToggle();
    accordionShortcode();
    heroSlider();
    faqsBlock();
    fiftyFiftyTextMarkets();
    collectionsBlock();
    marketsBlock();
    productFilters();
    behindTheDesign();
    productVideoGallery();
    productAdditionalImages();
    productGallery();
    productInfoTabs();
    contentImageSlider();
    marketAccordions();
    fiftyFiftyAccordions();
    featuredProductSlider();
    featuredInstallations();

    $(".submenu-toggle").on("click", function () {
      $(this).closest(".menu-item").toggleClass("menu-item--open");
    });

    $(".color-picker-choice").on("click", function () {
      var value = $(this).find("input").val(),
        label = $(this).closest(".color-picker-field").find(".gfield_label");

      if (label.find(".color").length) {
        label.find(".color").text(value);
      } else {
        label.append('<span class="color">' + value + "</span>");
      }
    });

    setTimeout(function () {
      $(".color-picker-choice-selected").each(function () {
        $(this).click();
      });
    }, 500);
  });

  // The sidebar menu
  function sidebarMenu() {
    var toggle = $("#sidebar-toggle"),
      sidebar = $("#sidebar-menu");

    // Open-close lateral menu clicking on the menu icon
    toggle.on("click", function (event) {
      event.preventDefault();

      toggle.toggleClass("site-header__menu-toggle--open");
      header.toggleClass("lateral-menu-is-open");
      siteContent.toggleClass("lateral-menu-is-open");
      sidebar.toggleClass("lateral-menu-is-open");
      $(".menu-item-object-product_category").removeClass("dropdown-open");
      siteContent.removeClass("site-content--overlay");
      header.removeClass("site-header--dropdown-open");
      $(".mtsnb").toggleClass("lateral-menu-is-open");
    });

    // Close lateral menu clicking outside the menu itself
    siteContent.on("click", function () {
      toggle.removeClass("site-header__menu-toggle--open");
      header.removeClass("lateral-menu-is-open");
      siteContent.removeClass("lateral-menu-is-open");
      $(".mtsnb").removeClass("lateral-menu-is-open");
      sidebar.removeClass("lateral-menu-is-open");
    });

    // Submenu toggles
    sidebar.find(".menu-item-has-children").each(function () {
      var menuItem = $(this),
        toggle = $(this).find(".submenu-toggle"),
        submenu = menuItem.find(".sub-menu");

      if (menuItem.hasClass("current-menu-ancestor")) {
        menuItem.addClass("menu-item--open");
      }

      toggle.click(function (event) {
        event.preventDefault();

        menuItem.toggleClass("menu-item--open");
        submenu.slideToggle(200, "linear");
      });
    });
  }

  // Header search toggle
  function searchToggle() {
    $("#search-toggle").on("click", function () {
      $(".menu-item-object-product_category").removeClass("dropdown-open");
      siteContent.removeClass("site-content--overlay");
      header.toggleClass("site-header--search-open");
      $("#header-search").focus();
      header.removeClass("site-header--dropdown-open");
    });

    $("body").on("click", function (event) {
      if (!$(event.target).is("#search-toggle, #header-search")) {
        header.removeClass("site-header--search-open");
      }
    });
  }

  // Accordion shortcode
  function accordionShortcode() {
    $(".accordion-shortcode__toggle").on("click", function () {
      var accordion = $(this).parent(".accordion-shortcode"),
        content = $(this).prev(".accordion-shortcode__content");

      content.slideToggle();
      accordion.toggleClass("accordion-shortcode--open");
    });
  }

  // Home hero slider
  function heroSlider() {
    var slider = $("#hero-slider");
    slider.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      speed: 300,
      autoplay: true,
      autoplaySpeed: 5000,
      infinite: true,
      adaptiveHeight: false,
    });
  }

  // FAQs block
  function faqsBlock() {
    $(".faqs-block__heading").on("click", function (e) {
      e.preventDefault();

      var faq = $(this).closest(".faqs-block__faq"),
        content = faq.find(".faqs-block__content");

      content.slideToggle(300);
      faq.toggleClass("faqs-block__faq--open");

      $(".faqs-block__content").not(content).slideUp(300);
      $(".faqs-block__faq").not(faq).removeClass("faqs-block__faq--open");
    });
  }

  // Market accordions
  function marketAccordions() {
    $(".market-accordions__heading").on("click", function (e) {
      e.preventDefault();

      var accordion = $(this).closest(".market-accordions__accordion"),
        content = accordion.find(".market-accordions__content");

      content.slideToggle(300);
      accordion.toggleClass("market-accordions__accordion--open");

      $(".market-accordions__content").not(content).slideUp(300);
      $(".market-accordions__accordion")
        .not(accordion)
        .removeClass("market-accordions__accordion--open");
    });
  }

  // 50/50 accordions
  function fiftyFiftyAccordions() {
    $(".fifty-accordion__heading").on("click", function (e) {
      e.preventDefault();

      var accordion = $(this).closest(".fifty-accordion__accordion"),
        content = accordion.find(".fifty-accordion__content");

      content.slideToggle(300);
      accordion.toggleClass("fifty-accordion__accordion--open");

      $(".fifty-accordion__content").not(content).slideUp(300);
      $(".fifty-accordion__accordion")
        .not(accordion)
        .removeClass("fifty-accordion__accordion--open");
    });
  }

  // 50/50 Text/Markets block
  function fiftyFiftyTextMarkets() {
    var slider = $(".text-markets__markets-slider");
    slider.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      speed: 300,
      autoplay: true,
      autoplaySpeed: 5000,
      infinite: true,
    });

    $(".text-markets__markets-next").on("click", function () {
      slider.slick("slickNext");
    });

    $(".text-markets__markets-prev").on("click", function () {
      slider.slick("slickPrev");
    });
  }

  // Featured product block slider
  function featuredProductSlider() {
    var slider = $(".featured-product__slider");
    slider.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      speed: 300,
      autoplay: true,
      autoplaySpeed: 5000,
      infinite: true,
    });

    $(".featured-product__next").on("click", function () {
      slider.slick("slickNext");
    });

    $(".featured-product__prev").on("click", function () {
      slider.slick("slickPrev");
    });
  }

  // Collections block
  function collectionsBlock() {
    var slider = $(".collections-block__collections");
    slider.slick({
      arrows: false,
      speed: 300,
      autoplay: true,
      autoplaySpeed: 5000,
      infinite: true,
      centerMode: true,
      centerPadding: "60px",
      slidesToShow: 3,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            centerMode: true,
            centerPadding: "40px",
            slidesToShow: 1,
          },
        },
      ],
    });

    $(".collections-block__next").on("click", function () {
      slider.slick("slickNext");
    });

    $(".collections-block__prev").on("click", function () {
      slider.slick("slickPrev");
    });
  }

  // Markets block
  function marketsBlock() {
    var slider = $(".markets-block__slider");
    slider.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      speed: 300,
      autoplay: true,
      autoplaySpeed: 5000,
      infinite: true,
    });
  }

  // Content/Image Slider block
  function contentImageSlider() {
    var slider = $(".content-slider__slider");
    slider.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      speed: 300,
      autoplay: true,
      autoplaySpeed: 5000,
      infinite: true,
      centerMode: true,
      centerPadding: "100px",
      responsive: [
        {
          breakpoint: 768,
          settings: {
            centerPadding: "60px",
          },
        },
      ],
    });
  }

  // Product filters
  function productFilters() {
    $(".products-block__filter-toggle").on("click", function (e) {
      e.preventDefault();

      var filter = $(this).next(".products-block__filter-wrap");

      filter.slideToggle(300);
      $(this).toggleClass("products-block__filter-toggle--open");
    });

    $(document).on("facetwp-refresh", function () {
      if (
        FWP.facets["product_search"].length > 0 ||
        FWP.facets["product_series"].length > 0 ||
        FWP.facets["product_work_space"].length > 0 ||
        FWP.facets["product_category"].length > 0
      ) {
        $(".products-block__series-links").hide();
        $(".products-block__products").removeClass(
          "products-block__products--hidden"
        );
      } else {
        $(".products-block__series-links").show();
        $(".products-block__products").addClass(
          "products-block__products--hidden"
        );
      }
    });
  }

  // Product "Behind The Design" popup
  function behindTheDesign() {
    $(".product-design__image-marker").on("click", function (e) {
      e.preventDefault();

      var popup = $(this).next(".product-design__marker-popup");

      popup.toggle();

      $(".product-design__marker-popup").not(popup).hide();
    });

    $(".product-design__popup-close").on("click", function (e) {
      e.preventDefault();

      var popup = $(this).closest(".product-design__marker-popup");

      popup.toggle();
    });
  }

  // Single product video gallery slider
  function productVideoGallery() {
    var slider = $(".product-videogallery__slider");
    slider.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      speed: 300,
      autoplay: false,
      infinite: true,
      adaptiveHeight: true,
    });

    $(".product-videogallery__slider-next").on("click", function () {
      slider.slick("slickNext");
    });

    $(".product-videogallery__slider-prev").on("click", function () {
      slider.slick("slickPrev");
    });
  }

  // Single product image gallery
  function productGallery() {
    $(".product-gallery").slickLightbox({
      itemSelector: "a",
      caption: "caption",
    });
  }

  // Single product additional images
  function productAdditionalImages() {
    var slider = $("#product-slider"),
      sliderNav = $("#product-slider-nav");

    slider.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      speed: 300,
    });

    sliderNav.slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      asNavFor: "#product-slider",
      focusOnSelect: true,
      arrows: false,
      draggable: false,
    });

    slider.on("afterChange", function (event, slick, currentSlide) {
      sliderNav.slick("slickGoTo", currentSlide);
      var currrentNavSlideElem = sliderNav.find(
        '.slick-slide[data-slick-index="' + currentSlide + '"]'
      );
      sliderNav.find(".slick-slide.is-active").removeClass("is-active");
      $(currrentNavSlideElem).addClass("is-active");
    });
  }

  // Product info tabs
  function productInfoTabs() {
    $(".product-infotabs__content:first-child").addClass(
      "product-infotabs__content--open"
    );

    $(".product-infotabs__content-tab")
      .first()
      .addClass("product-infotabs__tab--selected");

    $(".product-infotabs__content-tab").on("click", function (e) {
      e.preventDefault();

      $(".product-infotabs__content-tab").removeClass(
        "product-infotabs__tab--selected"
      );
      $(this).addClass("product-infotabs__tab--selected");

      var id = $(this).data("id");

      $(".product-infotabs__content").removeClass(
        "product-infotabs__content--open"
      );
      $('.product-infotabs__content[data-id="' + id + '"]').addClass(
        "product-infotabs__content--open"
      );
    });
  }

  // Featured installations block
  function featuredInstallations() {
    $(".featured-installations__collapsed-expand").on("click", function () {
      var collapsed = $(this).parent(".featured-installations__collapsed");

      collapsed.toggleClass("featured-installations__collapsed--open");
    });
  }
})(jQuery);
